<template>
  <r-container class="container-fluid">
    <r-row>
      <r-col class="col-12">
        <r-table-crud
          :key="key"
          link="user/tickets"
          :query="status.value >= 0 ? 'f=' + status['value'] : undefined"
          :actions="[
            {
              name: 'call',
              color: 'info',
              icon: $r.icons.eye
            }
          ]"
          disable-update
          disable-delete
          @call="select"
        >
          <template #form="{ok,options}">
            <r-card>
              <r-container>
                <r-row>
                  <r-col class="col-12">
                    <r-text-input
                      :label="$t('title','renusify')"
                      v-model="form_data.title"
                      :rules="['required']"
                    ></r-text-input>
                  </r-col>
                  <r-col class="col-12">
                    <r-select :label="$t('department','renusify')" v-model="form_data.department" :rules="['required']" :items="options.department.items" justValue translate></r-select>
                  </r-col>
                  <r-col class="col-12">
                    <r-select :label="$t('importance','renusify')" v-model="form_data.importance" :rules="['required']" :items="options.importance.items" justValue translate open-to-top></r-select>
                  </r-col>
                  <r-col class="col-12">
                    <r-text-area :label="$t('description','renusify')" v-model="form_data.text" :rules="['required']"></r-text-area>
                  </r-col>
                </r-row>
                <r-row class="h-end">
                  <r-col class="col-auto">
                    <r-btn
                      :loading="loading"
                      class="color-success"
                      @click.prevent="send(ok)"
                      >{{ $t("send",'renusify') }}</r-btn
                    >
                  </r-col>
                </r-row>
              </r-container>
            </r-card>
          </template>
          <template #cell="{item,value}">
            <div
              v-if="value['value'] === 'title'"
              @click.prevent="select(item)"
            >
              <r-btn text>{{ item["title"] }}</r-btn>
            </div>
            <div v-else-if="value['value'] === 'department'||value['value'] === 'importance'">
              {{ $t(item[value["value"]],'renusify') }}
            </div>
            <div v-else-if="value['value'] === 'status'">
              {{ status_name(item["status"]) }}
            </div>
            <div v-else-if="value['value'] === 'created_at'">
              {{ $d(new Date(item["status"])) }}
            </div>
            <div v-else-if="value['value'] === 'updated_at'">
              <r-time-ago :time="new Date(item['status'])"></r-time-ago>
            </div>
            <div v-else>
              {{ item[value["value"]] }}
            </div>
          </template>
        </r-table-crud>
      </r-col>
    </r-row>
    <r-modal v-model="show" full-width bottom>
      <r-card>
        <send-form-ticket-user :id="id"></send-form-ticket-user>
      </r-card>
    </r-modal>
  </r-container>
</template>

<script>
import SendFormTicketUser from "./send_form_user";
export default {
  name: "renus-ticket",
  components: {SendFormTicketUser },
  data() {
    return {
      form_data:{},
      loading: false,
      show: false,
      key: 0,
      status: { name: this.$t("all",'renusify'), value: -1 },
      counts: { "0": 0, "1": 0, "2": 0 },
      id: null
    };
  },
  methods: {
    send(ok) {
      this.loading = true;
      this.$axios
        .post("user/tickets", this.form_data)
        .then(
          () => {
            this.loading = false;
            ok();
            this.form_data={}
          },
          () => {
            this.loading = false;
          }
        );
    },
    select(item) {
      this.show = true;
      this.id = item._id;
    },
    status_name(n) {
      if (n === -1) {
        return this.$t("all",'renusify');
      }
      if (n === 0) {
        return this.$t("open",'renusify');
      }
      if (n === 1) {
        return this.$t("pending",'renusify');
      }
      if (n === 2) {
        return this.$t("answered",'renusify');
      }
      if (n === 3) {
        return this.$t("close",'renusify');
      }
      return n;
    }
  }
};
</script>
