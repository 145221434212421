<template>
  <r-container>
    <r-infinite-div
      :url="'user/tickets/' + id"
      height="68vh"
      is-chat
      :live="live"
    >
      <template v-slot="props_t">
        <r-container>
          <r-row class="no-gutters" v-if="props_t.item.sender === 'user'">
            <r-col class="col-auto">
              <r-card class="pa-2 text-end color-me">
                <div v-if="props_t.item.files.length>0">
                  <img :src="'/'+props_t.item.files[0]" v-if="isImg(props_t.item.files)" alt="icon">
                  <a v-else target="_blank" :href="'/'+props_t.item.files[0]">
                               <r-icon v-html="$r.icons.file" width="100" height="100" class="color-one-text file"></r-icon>

                  </a>
                </div>
                <div

                  class="text-start text-pre-wrap"
                  v-text="$helper.cleanXss(props_t.item.text)"
                ></div>
                <span class="caption">{{$d(new Date(props_t.item['updated_at']),'long')}}</span>
              </r-card>
            </r-col>
          </r-row>
          <r-row v-else class="h-end no-gutters">
            <r-col class="col-auto">
              <r-card class="pa-2 text-end">
                <div v-if="props_t.item.files.length>0">
                  <img :src="'/'+props_t.item.files[0]" v-if="isImg(props_t.item.files)" alt="icon">
                  <a v-else target="_blank" :href="'/'+props_t.item.files[0]">
                                <r-icon v-html="$r.icons.file" width="100" height="100" class="color-one-text file"></r-icon>
                  </a>
                </div>
                <div
                  class="text-start text-pre-wrap"
                  v-text="$helper.cleanXss(props_t.item.text)"
                ></div>
                <span class="caption">{{$d(new Date(props_t.item['updated_at']),'long')}}</span>
              </r-card>
            </r-col>
          </r-row>
        </r-container>
      </template>
    </r-infinite-div>
    <r-row>
      <r-col>
        <r-text-area v-model="text" rows="4"></r-text-area>
      </r-col>
      <r-col class="col-auto pt-8">
        <r-btn
          icon
          :loading="loading"
          @click.prevent="send()"
          class="color-info"
        >
          <r-icon v-html="$r.icons.send"></r-icon>
        </r-btn>
        <br>
        <r-btn icon class="color-info mt-2" @click.prevent="open=true">
          <r-icon v-html="$r.icons.attachment"></r-icon>
        </r-btn>
      </r-col>
    </r-row>
    <r-modal v-model="open">
      <r-card>
        <r-file-uploader :size="1" uploadLink="/storage" v-model="files" @add="send(true)"></r-file-uploader>
      </r-card>
    </r-modal>
  </r-container>
</template>

<script>
export default {
  name: "sendFormTicket",
  props: {
    id: String
  },
  data() {
    return {
      open: false,
      files:[],
      loading: false,
      live: false,
      text: null
    };
  },
  methods: {
    send(file=false) {
      if(file && this.files.length===0){
        return
      }
      this.loading = true;
      this.live = false;
      this.$axios
        .post("user/tickets/" + this.id, {
          text: this.text,
          files:this.files
        })
        .then(
          () => {
            this.open = false;
            this.loading = false;
            this.live = true;
            this.text = null;
            this.files = [];
          },
          () => {
            this.loading = false;
          }
        );
    },
    isImg(files){
      let file=files[0]
      if(!file){
        return false
      }
      file=file.split('.')
      if(file[0].startsWith('storage/img')){
        return true
      }
      return false
    }
  }
};
</script>
<style lang="scss">
.color-me {
  background-color: #26b4c1 !important;
  color: white !important;
}
</style>
